export const Defines = {
  Fanout: {
    Status: {
      Starting: "STARTING",
      Running: "RUNNING",
      Restarting: "RESTARTING",
      Stopped: "STOPPED",
      Update: "status-update",
    },
    VirtualUser: "mainProducer",
    Signalling: {
      GetContext: "get-initial-context",
      CreateRoom: "create-room",
      JoinConference: "join-conference",
      JoinRoom: "join-room",
      PeekRoom: "peek-room",
      Initial: "join-room",
      Transport: {
        Create: "create-transport",
        Connect: "connect-transport",
        Recv: 1,
        Send: 2
      },
      Media: {
        Produce: "produce",
        ProduceResume: "produce-resume",
        ProducePause: "produce-pause",
        Consume: "consume",
        ConsumeResume: "consume-resume",
        ConsumePause: "consume-pause",
        NewProducer: "new-producer",
        ProducerDeleted: "producer-deleted",
        DeleteProducer: "delete-producer",
        SyncProducers: "sync-producers",
        ProducerPause: "producer-pause",
        ProducerResume: "producer-resume",
      },
      DataChannel: {
        Produce: "data-produce",
        Consume: "data-consume",
        ConsumeAck: "data-consuming",
        NewProducer: "new-data-producer"
      },
      Meta: {
        Layout: "layout-update",
        VideoPresentation: "video-presentation-update",
      },
      Auth: "auth",
      Register: "register",
      UpdateIdToken: "update-id-token",
      VerifyLink: "verify-link",
      VerifyEvent: "verify-event",
      VerifyEmail: "verify-email",
      VerifyTicket: "verify-ticket",
      RequestRoomAccess: "request-room-access",
      CreateGuestTicket: "create-guest-ticket",
      CreateMeGuestTicket: "create-me-guest-ticket",
      GetInvitationLink: "get-me-invitation-link",
      ActiveSpeaker: "active-speaker",
      UpdateUser: "update-user",
      BanUser: "ban-user",
      Streaming: "streaming",
      Started: "started",
      Ended: "ended",
      End: "end",
      Stop: "stop",
      EndForAudience: "end-audience",
      Extend: "extend-streaming",
      ForceStop: "force-stop",
      Reconnect: "reconnect",
      ReconnectMe: "reconnect-me",
      Disconnected: "disconnected",
      GetReady: "get-ready",
      ChangeLayout: "change-layout",
      OnVideoClick: "on-video-click",
      AtEase: "at-ease",
      Join: "join",
      Cancelled: "cancelled",
      Closed: "peer-closed",
      Error: "error",
      Warn: "warn",
      Log: "log",
    },
    Events: {
      Metadata: {
        Event: "metaData",
        Types: {
          Layout: "layout",
          Swap: "swap",
          Active: "active",
          Stop: "remove"
        }
      }
    },
    DataChannel: {
      VideoPresentation: "Video_Update",
      SpeakerLayout: "Speaker_Metadata",
      Chat: "Chat_Message"
    },
    Chat: {
      Connect: "chat-connect",
      Disconnect: "chat-disconnect",
      Message: "chat-message",
      Hosts: "chat-hosts",
      Audience: "chat-audience",
      History: "chat-history",
      BannedUsers: "chat-banned-users",
      DeleteMessage: "chat-delete-message",
      BanUserByMessage: "chat-ban-user-by-message"
    },
    Knock: {
      Request: "knock-request",
      Requests: "knock-requests",
      Granted: "knock-granted",
      Rejected: "knock-hosts",
      Revoked: "knock-revoked"
    },
    Dolby: {
      GetToken: "dolby-get-token",
      RefreshToken: "dolby-refresh-token",
    },
    Noop: "noop"
  },
  DolbyEvents: {
    VideoPresentation: {
      Started: "started",
      Stopped: "stopped",
      Played: "played",
      Paused: "paused",
      Sought: "sought"
    }
  },
  VideoPresentation: {
    Started: "started",
    Stopped: "stopped",
    Played: "played",
    Paused: "paused",
    Sought: "sought"
  },
  RecordingStatus: {
    Unknown: "unknown",
    GettingReady: "getting-ready",
    Ready: "ready",
    InProgress: "in-progress",
    Done: "done",
    Disconnected: "disconnected",
    Error: "error",
  },
  RecordingLayout: {
    NoCrop: "no-crop",
    Crop: "crop",
    Speaker: "speaker",
    AdParticipants: "ad-participants"
  },
  Labels: {
    Default: 'default',
    Outlined: 'outlined'
  },
  Response: {
    OK: 200,
    BadReques: 400,
    Unauthorized: 401,
    Forbidden: 403,
    NotFound: 404,
    NotAcceptable: 406,
    Conflict: 409,
    Gone: 410,
    TooEarly: 425,
    TooManyRequests: 429
  },
  Resolution: {
    FullHD: '1080p',
    HDReady: '720p',
  },
  VideoCodec: {
    VP9: 'VP9',
    VP8: 'VP8',
  },
  Quality: {
    LOW: 'low',
    MEDIUM: 'medium',
    HIGH: 'high'
  }
};